import React, { useEffect } from 'react';
import './App.css';
import titleImage from './images/title.png';
import movieImage1 from './images/jellyfin.png';
import movieImage2 from './images/cloud.png';
import movieImage3 from './images/emby.png'; 

const MovieCard = ({ title, imageUrl, link }) => (
    <div className="card" onClick={() => window.location.href = link}>
        <div className="card-image">
            <img src={imageUrl} alt={title} />
        </div>
        <div className="card-content">
            <h3>{title}</h3>
        </div>
    </div>
);

function App() {
    useEffect(() => {
        document.title = "Alsättersgatans Shitflix";
    }, []);
    
    return (
        <div className="App">
            <div className="title-pill">
                <img src={titleImage} alt="Alsättersgatan Shitflix" />
            </div>
            <div className="card-container">
                <MovieCard
                    title="Se film"
                    imageUrl={movieImage1}
                    link="https://jmediaserver.reuterskiold.dev/"
                />
                <MovieCard
                    title={
                        <span>
                            Se film <i style={{ fontStyle: 'italic' }}>(Rekommenderas)</i>
                        </span>
                    }
                    imageUrl={movieImage3}
                    link="https://emediaserver.reuterskiold.dev/"
                />                
                <MovieCard
                    title="Begär ny film"
                    imageUrl={movieImage2}
                    link="https://filmrequest.reuterskiold.dev"
                />
            </div>
        </div>
    );
}

export default App;
